import React, { useState } from "react";
import MDInput from "../../components/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import Grid from "@mui/material/Grid";
import { NumericFormat } from "react-number-format";
import MDButton from "../../components/MDButton";
import "react-datepicker/dist/react-datepicker.css";

import { useMember } from "../../hooks/useMember";
import { useBakingType } from "../../hooks/useBankingType";
import RangeDatePicker from "../../components/RangeDatePicker";
import { bankingStatusOptions } from "../../utils/constants";
import MDTypography from "../../components/MDTypography";
import { useCard } from "../../hooks/useCard";
import Switch from "@mui/material/Switch";
import { FormControlLabel } from "@mui/material";
import { autocompleteStyles } from "../../assets/styles/autocomplete";
import { useUser } from "../../hooks/useUser";

// CSS Modules, react-datepicker-cssmodules.css
// import 'react-datepicker/dist/react-datepicker-cssmodules.css';
const options = [
  { label: "Thời gian giao dịch", value: "bankingTime" },
  { label: "Hậu đài", value: "company" },
  { label: "Thẻ nhận", value: "toCard" },
  { label: "Thẻ chuyển", value: "fromCard" },
  { label: "Số tiền giao dịch", value: "amountOfMoney" },
  { label: "Nội dung", value: "content" },
];
const initialState = {
  fromCard: [],
  toCard: [],
  cards: [],
  amountOfMoneyRange: [null, null],
  bankingTime: [null, null],
  members: [],
  bankingType: null,
  company: null,
  filterDuplicate: [],
  status: [],
  createdBy: null,
  updatedBy: null,
};

const AdvancedSearch = ({ onSearch }) => {
  const { data: memberData } = useMember();
  const { data: bankingTypeData } = useBakingType();
  const { data: cardData } = useCard();
  const { data: userData } = useUser({
    getAll: true,
  });

  const [searchValue, setSearchValue] = useState(initialState);
  const [typeSearch, setTypeSearch] = useState(1); // 1 : specific, 2: all card
  const handleSearchChange = (value, type) => {
    setSearchValue((prevState) => {
      return {
        ...prevState,
        [type]: value,
      };
    });
  };
  const handleChangeAmountAMoney = (event, side) => {
    const sanitized = event.target.value.replace(/,/g, "");
    // convert string to number
    const number = Number(sanitized);
    if (side === "min") {
      const value = [number, searchValue?.amountOfMoneyRange[1]];
      handleSearchChange(value, "amountOfMoneyRange");
      return;
    }
    if (side === "max") {
      const value = [searchValue?.amountOfMoneyRange[0], number];
      handleSearchChange(value, "amountOfMoneyRange");
    }
  };
  return (
    <Grid container spacing={2}>
      <Grid item display="flex" alignItems="center" gap="10px" flexWrap="wrap" xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            {/* Switch */}

            <FormControlLabel
              control={
                <Switch
                  checked={typeSearch === 1}
                  onClick={() => {
                    setTypeSearch(typeSearch === 1 ? 2 : 1);
                  }}
                />
              }
              label="Tìm kiếm theo thẻ hoặc tất cả thẻ"
            />
          </Grid>
          {typeSearch === 1 ? (
            <>
              <Grid item xs={3}>
                <Autocomplete
                  options={cardData || []}
                  onChange={(event, newValue) => {
                    handleSearchChange(newValue, "fromCard");
                  }}
                  multiple
                  size="small"
                  value={searchValue.fromCard}
                  isOptionEqualToValue={(option, value) => {
                    if (!value) return false;
                    return option === value;
                  }}
                  getOptionLabel={(option) => {
                    return [option?.account, option?.accountNumber, option?.bank?.name]
                      .filter(Boolean)
                      .join(" - ");
                  }}
                  sx={autocompleteStyles}
                  renderInput={(params) => {
                    return (
                      <MDInput
                        {...params}
                        label="THẺ CHUYỂN"
                        // onChange={(e) => {
                        //   setFromCardSearchInput(e.target.value);
                        // }}
                      />
                    );
                  }}
                />
              </Grid>
              <Grid item xs={3}>
                <Autocomplete
                  multiple
                  options={cardData || []}
                  value={searchValue.toCard}
                  onChange={(event, newValue) => {
                    handleSearchChange(newValue, "toCard");
                  }}
                  getOptionLabel={(option) => {
                    return [option?.account, option?.accountNumber, option?.bank?.name]
                      .filter(Boolean)
                      .join(" - ");
                  }}
                  sx={autocompleteStyles}
                  isOptionEqualToValue={(option, value) => option === value}
                  size="small"
                  renderInput={(params) => <MDInput {...params} label="THẺ NHẬN" />}
                />
              </Grid>
            </>
          ) : (
            <Grid item xs={6}>
              <Autocomplete
                options={cardData || []}
                onChange={(event, newValue) => {
                  handleSearchChange(newValue, "cards");
                }}
                multiple
                size="small"
                value={searchValue.cards}
                isOptionEqualToValue={(option, value) => {
                  if (!value) return false;
                  return option === value;
                }}
                getOptionLabel={(option) => {
                  return [option?.account, option?.accountNumber, option?.bank?.name]
                    .filter(Boolean)
                    .join(" - ");
                }}
                sx={autocompleteStyles}
                renderInput={(params) => {
                  return (
                    <MDInput
                      {...params}
                      label="THẺ"
                      // onChange={(e) => {
                      //   setFromCardSearchInput(e.target.value);
                      // }}
                    />
                  );
                }}
              />
            </Grid>
          )}
          {/*<Grid item xs={3}>*/}
          {/*  <Autocomplete*/}
          {/*    value={searchValue.fromCardNumber}*/}
          {/*    options={fromCardNumberOptions}*/}
          {/*    onChange={(event, newValue) => {*/}
          {/*      handleSearchChange(newValue, "fromCardNumber");*/}
          {/*    }}*/}
          {/*    size="small"*/}
          {/*    isOptionEqualToValue={(option, value) => option === value}*/}
          {/*    renderInput={(params) => <MDInput {...params} label="SỐ THẺ CHUYỂN" />}*/}
          {/*  />*/}
          {/*</Grid>*/}
          {/*<Grid item xs={3}>*/}
          {/*  <Autocomplete*/}
          {/*    value={searchValue.toCardNumber}*/}
          {/*    options={toCardNumberOptions}*/}
          {/*    onChange={(event, newValue) => {*/}
          {/*      handleSearchChange(newValue, "toCardNumber");*/}
          {/*    }}*/}
          {/*    size="small"*/}
          {/*    isOptionEqualToValue={(option, value) => option === value}*/}
          {/*    renderInput={(params) => <MDInput {...params} label="SỐ THẺ NHẬN" />}*/}
          {/*  />*/}
          {/*</Grid>*/}
          <Grid item xs={3}>
            <RangeDatePicker
              selected={searchValue.bankingTime}
              onChange={(dates) => {
                handleSearchChange(dates, "bankingTime");
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              value={searchValue.members}
              options={memberData || []}
              getOptionLabel={(option) => {
                return option?.name;
              }}
              onChange={(event, newValue) => {
                handleSearchChange(newValue, "members");
              }}
              size="small"
              multiple
              sx={autocompleteStyles}
              isOptionEqualToValue={(option, value) => {
                return option?._id === value?._id;
              }}
              renderInput={(params) => <MDInput {...params} label="Thành Viên" />}
            />
            {/*<MultipleSelectChip*/}
            {/*  size="small"*/}
            {/*  title="Thành Viên"*/}
            {/*  data={memberData || []}*/}
            {/*  dataSelected={searchValue.members || []}*/}
            {/*  onChange={(value) => {*/}
            {/*    handleSearchChange(value, "members");*/}
            {/*  }}*/}
            {/*  sx={{*/}
            {/*    marginTop: 0,*/}
            {/*    "& .MuiOutlinedInput-root": {*/}
            {/*      padding: pxToRem(9),*/}
            {/*    },*/}
            {/*  }}*/}
            {/*/>*/}
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              value={searchValue.bankingType}
              options={bankingTypeData || []}
              onChange={(event, newValue) => {
                handleSearchChange(newValue, "bankingType");
              }}
              size="small"
              getOptionLabel={(option) => option.name}
              isOptionEqualToValue={(option, value) => {
                return option._id === value._id;
              }}
              sx={autocompleteStyles}
              renderInput={(params) => <MDInput {...params} label="Kiểu giao dịch" />}
            />
          </Grid>
          <Grid item xs={3}>
            <MDInput
              value={searchValue.company}
              onChange={(event) => {
                const value = event?.target?.value;
                handleSearchChange(value, "company");
              }}
              label="Hậu đài"
              size="small"
              sx={{
                width: "100%",
              }}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              value={searchValue.createdBy}
              options={userData?.data || []}
              onChange={(event, newValue) => {
                handleSearchChange(newValue, "createdBy");
              }}
              size="small"
              getOptionLabel={(option) => option?.fullName}
              isOptionEqualToValue={(option, value) => {
                return option._id === value;
              }}
              sx={autocompleteStyles}
              renderInput={(params) => <MDInput {...params} label="Người tạo" />}
            />
          </Grid>
          <Grid item xs={3}>
            <Autocomplete
              value={searchValue.updatedBy}
              options={userData?.data || []}
              onChange={(event, newValue) => {
                handleSearchChange(newValue, "updatedBy");
              }}
              size="small"
              getOptionLabel={(option) => option?.fullName}
              isOptionEqualToValue={(option, value) => {
                return option._id === value._id;
              }}
              sx={autocompleteStyles}
              renderInput={(params) => <MDInput {...params} label="Người cập nhật" />}
            />
          </Grid>
        </Grid>
      </Grid>
      {/*banking status*/}
      <Grid item xs={3}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{
            flexWrap: "nowrap",
          }}
        >
          <Grid item>
            <MDTypography
              gutterBottom
              sx={{
                fontSize: "12px",
              }}
              color="text"
            >
              Trạng thái giao dịch
            </MDTypography>
          </Grid>
          <Grid item xs={8}>
            <Autocomplete
              multiple
              size="small"
              options={bankingStatusOptions}
              value={searchValue.status}
              renderInput={(params) => <MDInput {...params} label="Trạng thái giao dịch" />}
              onChange={(event, newValue) => {
                handleSearchChange(newValue, "status");
              }}
              sx={autocompleteStyles}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={6}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{
            flexWrap: "nowrap",
          }}
        >
          <Grid item>
            <MDTypography
              gutterBottom
              sx={{
                fontSize: "12px",
              }}
              color="text"
            >
              Khoảng chi tiêu
            </MDTypography>
          </Grid>

          <Grid item>
            <NumericFormat
              value={searchValue.amountOfMoneyRange[0] || ""}
              size="small"
              thousandSeparator
              customInput={MDInput}
              onChange={(e) => {
                handleChangeAmountAMoney(e, "min");
              }}
            />
          </Grid>
          <Grid item>
            <MDTypography
              gutterBottom
              sx={{
                fontSize: "12px",
              }}
              color="text"
            >
              -
            </MDTypography>
          </Grid>
          <Grid item>
            <NumericFormat
              value={searchValue.amountOfMoneyRange[1] || ""}
              size="small"
              thousandSeparator
              customInput={MDInput}
              onChange={(e) => {
                handleChangeAmountAMoney(e, "max");
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={3}>
        <Grid
          container
          spacing={2}
          alignItems="center"
          sx={{
            flexWrap: "nowrap",
            width: "100%",
          }}
        >
          <Grid item>
            <MDTypography
              gutterBottom
              sx={{
                fontSize: "12px",
              }}
              color="text"
            >
              Lọc trùng
            </MDTypography>
          </Grid>
          {/*Filter duplicate*/}
          <Grid item width="100%">
            <Autocomplete
              value={searchValue.filterDuplicate}
              onChange={(event, newValue) => {
                handleSearchChange(newValue, "filterDuplicate");
              }}
              sx={{
                ...autocompleteStyles,
                width: "100%",
              }}
              multiple
              options={options}
              isOptionEqualToValue={(option, value) => option === value}
              getOptionLabel={(option) => {
                return option?.label;
              }}
              size="small"
              renderInput={(params) => <MDInput {...params} label="Select field" />}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid
        item
        xs={12}
        border="1px solid"
        display="flex"
        justifyContent="flex-end"
        alignItems="center"
        paddingInline="10px"
        paddingBlock="5px"
        marginTop="10px"
      >
        <MDButton
          variant="outlined"
          color="success"
          onClick={() => {
            if (onSearch) {
              if (typeSearch === 1) {
                onSearch({
                  ...searchValue,
                  filterDuplicate: searchValue?.filterDuplicate?.map((item) => item.value),
                  bankingType: searchValue?.bankingType?._id || null,
                  members: searchValue?.members?.map((member) => member._id),
                  status: searchValue?.status?.map((status) => status.value),
                  fromCard: searchValue?.fromCard?.map((_) => _._id),
                  toCard: searchValue?.toCard?.map((_) => _._id),
                  cards: [],
                  createdBy: searchValue?.createdBy?._id || null,
                  updatedBy: searchValue?.updatedBy?._id || null,
                });
              } else {
                onSearch({
                  ...searchValue,
                  filterDuplicate: searchValue?.filterDuplicate?.map((item) => item.value),
                  bankingType: searchValue?.bankingType?._id || null,
                  members: searchValue?.members?.map((member) => member._id),
                  status: searchValue?.status?.map((status) => status.value),
                  fromCard: [],
                  toCard: [],
                  cards: searchValue?.cards?.map((_) => _._id),
                  createdBy: searchValue?.createdBy?._id || null,
                  updatedBy: searchValue?.updatedBy?._id || null,
                });
              }
            }
          }}
          sx={{
            marginRight: "10px",
          }}
        >
          Search
        </MDButton>

        <MDButton
          variant="outlined"
          color="error"
          onClick={() => {
            setSearchValue(initialState);
            onSearch &&
              onSearch({
                ...initialState,
                filterDuplicate: [],
                bankingType: null,
                members: [],
              });
          }}
        >
          Clear
        </MDButton>
      </Grid>
    </Grid>
  );
};

export default AdvancedSearch;
