import React, { useMemo, useState } from "react";
import MDInput from "../../components/MDInput";
import { useQuery } from "@tanstack/react-query";
import { method } from "../../libs/fetchRequestUtils";
import { bankQueryKey } from "../../hooks/useBank";
import { useRequest } from "../../context/RequestContext";
import Autocomplete from "@mui/material/Autocomplete";
import { useCard } from "../../hooks/useCard";
import { isArray } from "lodash";

const SearchCard = ({ onChange }) => {
  const [accountNumber, setAccountNumber] = useState("");
  const [bank, setBank] = useState("");
  const [open, setOpen] = useState(false);
  const [cardCategory, setCardCategory] = useState("");
  const [account, setAccount] = useState("");
  const { makeRequest } = useRequest();

  const { data: listCard } = useCard();

  const listNameCard = useMemo(() => {
    if (!listCard) return [];
    const listName = new Set();
    listCard.forEach((card) => {
      listName.add(card?.account || card?.cardName);
    });
    return Array.from(listName);
  }, [listCard]);

  const cardCategoryList = useMemo(() => {
    if (!listCard) return [];

    const listCategoryId = new Set();
    const listCategory = [];

    listCard.forEach((card) => {
      const listCardCategory = card.cardCategories;

      if (listCardCategory && isArray(listCardCategory)) {
        listCardCategory.map((item) => {
          listCategoryId.add(item?._id);
          listCategory.push(item);
        });
      }
    });
    const result = Array.from(listCategoryId).map((id) => {
      return listCategory.find((item) => item?._id === id);
    });
    // Add the record with _id as null and name as "không có" in first position
    result.unshift({ _id: "none", name: "Không có" });

    return result;
  }, [listCard]);
  const { data, isLoading, error } = useQuery({
    queryKey: [bankQueryKey],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${bankQueryKey}/getAll`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      const userList = data;
      // change username to label
      userList.forEach((user) => {
        user.label = user.name;
      });
      return userList;
    },
    enabled: open,
    placeholderData: () => {
      return [];
    },
  });

  const handleChanges = (type, value) => {
    // add more type here
    const setters = {
      accountNumber: setAccountNumber,
      bank: setBank,
      cardCategory: setCardCategory,
      account: setAccount,
    };

    const states = {
      accountNumber,
      bank,
      cardCategory,
      account,
    };

    if (setters[type]) {
      setters[type](value);
      onChange && onChange({ ...states, [type]: value });
    }
  };
  const handleChangeBank = (event, newValue) => {
    const value = newValue?._id || "";
    handleChanges("bank", value);
  };

  return (
    <>
      <Autocomplete
        disablePortal
        options={listNameCard}
        sx={{ width: 175, flexShrink: 0, transform: "translateY(2px)" }}
        // getOptionLabel={(option) => option?.name}
        onChange={(e, newValue) => {
          handleChanges("account", newValue);
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            placeholder="Tên tài khoản ..."
            size="small"
            fullWidth
            sx={{ paddingTop: "8px", paddingBottom: "6px", transform: "translateY(-2px)" }}
          />
        )}
      />
      <Autocomplete
        disablePortal
        options={cardCategoryList}
        sx={{ width: 175, flexShrink: 0, transform: "translateY(2px)" }}
        getOptionLabel={(option) => option?.name}
        onChange={(e, newValue) => {
          handleChanges("cardCategory", newValue?._id);
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            placeholder="Bộ thẻ ..."
            size="small"
            fullWidth
            sx={{ paddingTop: "8px", paddingBottom: "6px", transform: "translateY(-2px)" }}
          />
        )}
      />
      <Autocomplete
        disablePortal
        options={data}
        sx={{ width: 175, flexShrink: 0, transform: "translateY(2px)" }}
        onOpen={() => {
          setOpen(true);
        }}
        onChange={handleChangeBank}
        loading={isLoading}
        renderInput={(params) => (
          <MDInput
            {...params}
            placeholder="Ngân Hàng..."
            size="small"
            fullWidth
            sx={{ paddingTop: "8px", paddingBottom: "6px", transform: "translateY(-2px)" }}
          />
        )}
      />

      <MDInput
        placeholder="STK"
        size="small"
        fullWidth
        value={accountNumber}
        onChange={({ currentTarget }) => {
          handleChanges("accountNumber", currentTarget.value);
        }}
      />
    </>
  );
};

export default SearchCard;
