import React, { useMemo } from "react";
import { useQuery } from "@tanstack/react-query";
import { method } from "../libs/fetchRequestUtils";
import { useRequest } from "../context/RequestContext";
import { get } from "lodash";

export const exchangeRateQueryKey = "exchangeRate";
const useExchangeRate = () => {
  const { makeRequest } = useRequest();
  const { data, isLoading, error } = useQuery({
    queryKey: [exchangeRateQueryKey],
    queryFn: async () => {
      const request = {
        method: method.get,
        endpoint: `/${exchangeRateQueryKey}`,
      };
      return await makeRequest(request);
    },
    select: (data) => {
      return data;
    },

    placeholderData: (previousData, previousQuery) => previousData,
  });

  const vndRate = useMemo(() => {
    const vnd = get(data, "VND", 1);

    return vnd;
  }, [data]);

  const khrRate = useMemo(() => {
    const khr = get(data, "KHR", 1);
    return khr;
  }, [data]);
  return {
    data,
    isLoading,
    vndRate,
    khrRate,
  };
};

export default useExchangeRate;
