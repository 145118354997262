import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import FormSelect from "components/FormSelect";

import { useRequest } from "context/RequestContext";
import React, { useEffect, useState } from "react";
import { useMaterialUIController } from "context";
import moneyTypeUtils from "utils/moneyTypeUtils";
import DatePicker from "components/DatePicker";
import { useQueryClient } from "@tanstack/react-query";
import { bankingQueryKey } from "../../hooks/useBanking";
import { cardQueryKey, useCard } from "../../hooks/useCard";
import { useBakingType } from "../../hooks/useBankingType";
import Autocomplete from "@mui/material/Autocomplete";
import { NumericFormat } from "react-number-format";
import {
  generateNumberListGreaterThanThreshold,
  isValidDate,
  separateThousands,
} from "../../utils/helpers";
import Chip from "@mui/material/Chip";
import QuillInputField from "../../components/QuillInputField";
import { bankingStatisticsQueryKey } from "../../hooks/useBankingStastic";
import { useMember } from "../../hooks/useMember";
import { FormControlLabel } from "@mui/material";
import Switch from "@mui/material/Switch";
import useExchangeRate from "../../hooks/useExchangeRate";
import MDTypography from "../../components/MDTypography";
import { bankingStatusOptions } from "../../utils/constants";

const inputStyle = {
  marginTop: "20px",
};

const context = "banking";

function UpdateForm({ banking, create = false }) {
  const { createRequest, updateRequest, dispatchError } = useRequest();

  const [amountOfMoney, setAmountOfMoney] = useState(Number(banking?.amountOfMoney) || "000");
  const [moneyType, setMoneyType] = useState(banking?.moneyType || 0);
  const [content, setContent] = useState(banking?.content || "");
  const [fromCard, setFromCard] = useState(banking?.fromCard || null);
  const [toCard, setToCard] = useState(banking?.toCard || null);
  const [bankingTime, setBankingTime] = useState(
    isValidDate(banking?.bankingTime) ? new Date(banking?.bankingTime) : new Date()
  );
  const [bankingType, setBankingType] = useState(banking?.bankingType || null);
  const [members, setMembers] = useState(() => {
    if (banking?.members) {
      return banking?.members.map((member) => member._id);
    }
    return [];
  });
  const [moneyList, setMoneyList] = useState([]);
  const [company, setCompany] = useState(banking?.company || "");
  const [isHighlight, setIsHighlight] = useState(banking?.isHighlight || false);
  const [usdExchangeRate, setUsdExchangeRate] = useState(banking?.usdExchangeRate || 1);
  const [usdExchangeRateKhr, setUsdExchangeRateKhr] = useState(banking?.usdExchangeRateKhr || 1);
  const [status, setStatus] = useState(() => {
    return (
      bankingStatusOptions.find((item) => item.value === banking?.status) || bankingStatusOptions[0]
    );
  });

  const [controller] = useMaterialUIController();
  const { sidenavColor } = controller;

  const { data: card, isLoading: isLoadingCard } = useCard({
    sort: "-recentUsed", // sort by recentUsed descending
  });
  const { data: bankingTypeRequest, isLoading: isLoadingBankingType } = useBakingType({
    sort: "createdTime", // sort by createdTime ascending
  });
  const { data: memberData } = useMember();
  const { vndRate, khrRate } = useExchangeRate();
  const [isLoading, setIsLoading] = useState(false);
  const [disableCreate, setDisableCreate] = useState(false);

  const queryClient = useQueryClient();

  useEffect(() => {
    if (banking?.usdExchangeRate) return;

    setUsdExchangeRate(vndRate);
  }, [vndRate]);

  useEffect(() => {
    if (create) {
      setUsdExchangeRate(vndRate);
      setUsdExchangeRateKhr(khrRate);
    } else {
      // check if usdExchangeRate is not equal to 1, then set usdExchangeRate to vndRate
      if (banking?.usdExchangeRate === 1) {
        setUsdExchangeRate(vndRate);
      }
      if (banking?.usdExchangeRateKhr === 1 || !banking?.usdExchangeRateKhr) {
        setUsdExchangeRateKhr(khrRate);
      }
    }
  }, [create, vndRate, khrRate]);

  const [isOpen, setIsOpen] = useState(false);

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      setIsLoading(true);
      const stringAmountOfMoney = amountOfMoney.toString();
      const body = {
        amountOfMoney: Number(stringAmountOfMoney),
        moneyType: Number(moneyType),
        fromCard: fromCard?._id,
        toCard: toCard?._id,
        bankingType: bankingType?._id,
        bankingTime: new Date(bankingTime).toISOString(),
        content,
        members,
        company,
        isHighlight,
        usdExchangeRate,
        usdExchangeRateKhr,
        status: status?.value,
      };

      if (!create) {
        await updateRequest({ context, id: banking._id, body });
        queryClient.invalidateQueries({
          queryKey: [bankingQueryKey],
          type: "active",
        });
        queryClient.invalidateQueries({
          queryKey: [bankingStatisticsQueryKey],
          type: "active",
        });
        queryClient.invalidateQueries({
          queryKey: [cardQueryKey],
          type: "all",
        });
      } else {
        const config = {
          autoClose: false,
        };
        await createRequest({ context, body, config });
        setBankingTime(new Date(bankingTime));
        setTimeout(() => {
          setDisableCreate(true);
        }, 0);
        queryClient.invalidateQueries({
          queryKey: [bankingQueryKey],
          type: "active",
        });
        queryClient.invalidateQueries({
          queryKey: [bankingStatisticsQueryKey],
          type: "active",
        });
        await queryClient.invalidateQueries({
          queryKey: [cardQueryKey],
          type: "active",
        });
      }
    } finally {
      setIsLoading(false);
    }
  }

  function handleBankingTimeOpen(e) {
    e.preventDefault();
    setIsOpen(!isOpen);
  }

  function handleResetForm() {
    setAmountOfMoney("000");
    setMoneyType(0);
    // setContent("");
    setFromCard(null);
    setToCard(null);
    setBankingTime(new Date(bankingTime));
    setBankingType(null);
    setMembers([]);
    setCompany("");
    setIsHighlight(false);
    setUsdExchangeRate(1);
    setStatus(bankingStatusOptions[0]);
    setTimeout(() => {
      setDisableCreate(false);
    }, 0);
  }

  if (isLoadingCard || isLoadingBankingType) return <div>Loading...</div>;
  return (
    <MDBox
      component="form"
      width={600}
      sx={{
        maxHeight: "80vh",
        overflowY: "auto",
      }}
      onSubmit={handleSubmit}
      // onClick={handleBankingTimeOpen}
    >
      {!isLoadingCard && (
        <Autocomplete
          disablePortal
          options={card}
          value={fromCard}
          sx={{
            ...inputStyle,
            width: "100%",
          }}
          renderInput={(params) => (
            <MDInput
              {...params}
              label="Thẻ Chuyển"
              helperText={fromCard?.isDeleted ? "Thẻ đã bị xóa" : ""}
            />
          )}
          getOptionLabel={(option) => {
            return `${option?.account} - ${option?.accountNumber || ""}`;
          }}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option?._id} className="MuiAutocomplete-option">
                {option?.account} - {option?.accountNumber || ""}
              </li>
            );
          }}
          isOptionEqualToValue={(option, value) => {
            return option._id === value._id;
          }}
          onChange={(e, newValue) => {
            // get all member of card
            const members = newValue?.members;
            if (members) {
              setMembers((prev) => {
                const newMembers = members.map((member) => member._id);
                //filter duplicate members
                return [...new Set([...prev, ...newMembers])];
              });
            }
            setFromCard(newValue);
          }}
        />
      )}
      {!isLoadingCard && (
        <Autocomplete
          disablePortal
          options={card}
          value={toCard}
          sx={{
            ...inputStyle,
            width: "100%",
          }}
          renderInput={(params) => (
            <MDInput
              {...params}
              label="Thẻ Nhận"
              helperText={toCard?.isDeleted ? "Thẻ đã bị xóa" : ""}
            />
          )}
          renderOption={(props, option) => {
            return (
              <li {...props} key={option?._id} className="MuiAutocomplete-option">
                {option?.account} - {option?.accountNumber || ""}
              </li>
            );
          }}
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          getOptionLabel={(option) => {
            return `${option?.account} - ${option?.accountNumber || ""}`;
          }}
          onChange={(e, newValue) => {
            // get all member of card
            const members = newValue?.members;
            if (members) {
              setMembers((prev) => {
                const newMembers = members.map((member) => member._id);
                //filter duplicate members
                return [...new Set([...prev, ...newMembers])];
              });
            }

            setToCard(newValue);
          }}
        />
      )}
      {/*{!isLoadingBankingType && (*/}
      {/*  <FormSelect*/}
      {/*    title="Kiểu Giao Dịch"*/}
      {/*    data={bankingTypeRequest}*/}
      {/*    dataSelected={bankingType}*/}
      {/*    onChange={(e) => setBankingType(e.target.value)}*/}
      {/*    sx={inputStyle}*/}
      {/*    required*/}
      {/*  />*/}
      {/*)} */}
      {!isLoadingBankingType && (
        <Autocomplete
          disablePortal
          options={bankingTypeRequest}
          value={bankingType}
          sx={{
            ...inputStyle,
            width: "100%",
          }}
          renderInput={(params) => (
            <MDInput
              {...params}
              label="Kiểu Giao Dịch"
              inputProps={{
                ...params.inputProps,
                required: true,
              }}
            />
          )}
          getOptionLabel={(option) => {
            return `${option?.name}`;
          }}
          isOptionEqualToValue={(option, value) => option?._id === value?._id}
          onChange={(e, newValue) => {
            setBankingType(newValue);
          }}
        />
      )}
      <Autocomplete
        required
        disablePortal
        id="combo-box-demo"
        options={memberData || []}
        multiple
        value={memberData?.filter((member) => members.includes(member._id))}
        sx={{
          ...inputStyle,
          width: "100%",
        }}
        renderInput={(params) => (
          <MDInput
            {...params}
            label="Thành Viên"
            inputProps={{
              ...params.inputProps,
              required: memberData?.length === 0,
            }}
          />
        )}
        getOptionLabel={(option) => {
          return `${option?.name}`;
        }}
        onChange={(e, newValue) => {
          const value = newValue?.map((item) => item._id);
          const isExitsCard = card?.find((item) => {
            // check card has member
            return item.members.some((member) => value.includes(member._id));
          });
          if (isExitsCard) {
            setFromCard(isExitsCard);
          }
          setMembers(value);
        }}
      />
      {/*<MultipleSelectChip*/}
      {/*  title="Thành Viên"*/}
      {/*  data={memberData || []}*/}
      {/*  dataSelected={members}*/}
      {/*  onChange={(value) => setMembers(value)}*/}
      {/*/>*/}
      <DatePicker
        key={disableCreate}
        formClick={isOpen}
        selected={bankingTime}
        onChange={(date) => setBankingTime(new Date(date).toISOString())}
      />
      <NumericFormat
        fullWidth
        id="amount-of-money"
        label="Khoản Chi Tiêu"
        variant="outlined"
        value={amountOfMoney}
        thousandSeparator
        customInput={MDInput}
        sx={inputStyle}
        onChange={(e) => {
          const value = e.target.value.replaceAll(",", "");
          // const firstChar = Number(value.charAt(0));
          const list = generateNumberListGreaterThanThreshold(value, 5);
          setMoneyList(list);
          setAmountOfMoney(value);
        }}
        required={true}
      />
      <MDBox sx={inputStyle}>
        {moneyList.map((item, index) => {
          return (
            <Chip
              key={index}
              variant="contained"
              color={sidenavColor}
              sx={{ marginRight: 2, marginBottom: 2 }}
              onClick={() => setAmountOfMoney(item)}
              label={separateThousands(item)}
            ></Chip>
          );
        })}
      </MDBox>
      <FormSelect
        title="Đơn Vị Tiền Tệ"
        data={moneyTypeUtils}
        dataSelected={moneyType}
        onChange={(e) => setMoneyType(Number(e.target.value))}
      />

      {/*{moneyType === 1 && (*/}
      {/*  <>*/}
      {/*    <NumericFormat*/}
      {/*      fullWidth*/}
      {/*      id="usdExchangeRate"*/}
      {/*      label="Tỷ giá USD"*/}
      {/*      variant="outlined"*/}
      {/*      value={usdExchangeRate}*/}
      {/*      thousandSeparator*/}
      {/*      customInput={MDInput}*/}
      {/*      sx={inputStyle}*/}
      {/*      onChange={(e) => {*/}
      {/*        const value = e.target.value.replaceAll(",", "");*/}
      {/*        setUsdExchangeRate(value);*/}
      {/*      }}*/}
      {/*      required={true}*/}
      {/*    />*/}
      {/*    /!*  show hint with vndRate  *!/*/}
      {/*    <MDBox sx={inputStyle}>*/}
      {/*      <MDTypography*/}
      {/*        variant="caption"*/}
      {/*        sx={{*/}
      {/*          color: "text.focus",*/}
      {/*        }}*/}
      {/*      >*/}
      {/*        Tỷ giá VND: {vndRate}*/}
      {/*      </MDTypography>*/}
      {/*    </MDBox>*/}
      {/*  </>*/}
      {/*)}*/}
      <NumericFormat
        fullWidth
        id="usdExchangeRate"
        label="Tỷ giá USD"
        variant="outlined"
        value={usdExchangeRate}
        thousandSeparator
        customInput={MDInput}
        sx={{
          ...inputStyle,
          display: moneyType === 0 ? "none" : "block",
        }}
        onChange={(e) => {
          const value = e.target.value.replaceAll(",", "");
          setUsdExchangeRate(value);
        }}
        required={true}
      />
      {/*  show hint with vndRate  */}
      <MDBox
        sx={{
          ...inputStyle,
          display: moneyType === 0 ? "none" : "block",
          marginTop: 0,
        }}
      >
        <MDTypography
          variant="caption"
          sx={{
            color: "text.focus",
          }}
        >
          Tỷ giá VND: {vndRate}
        </MDTypography>
      </MDBox>
      <NumericFormat
        fullWidth
        id="usdExchangeRateKhr"
        label="Tỷ giá USD sang KHR"
        variant="outlined"
        value={usdExchangeRateKhr}
        thousandSeparator
        customInput={MDInput}
        sx={{
          ...inputStyle,
          display: moneyType === 0 ? "none" : "block",
        }}
        onChange={(e) => {
          const value = e.target.value.replaceAll(",", "");
          setUsdExchangeRateKhr(value);
        }}
        required={true}
      />
      {/*  show hint with khrRate  */}
      <MDBox
        sx={{
          ...inputStyle,
          display: moneyType === 0 ? "none" : "block",
          marginTop: 0,
        }}
      >
        <MDTypography
          variant="caption"
          sx={{
            color: "text.focus",
          }}
        >
          Tỷ giá RIEL: {khrRate}
        </MDTypography>
      </MDBox>
      <MDInput
        fullWidth
        id="company"
        label="Hậu đài"
        variant="outlined"
        value={company}
        onChange={(e) => setCompany(e.target.value)}
        sx={inputStyle}
        required
      />
      {/*Company suggestion*/}
      <MDBox
        sx={{
          ...inputStyle,
          color: "text.focus",
        }}
      >
        <MDTypography
          variant="caption"
          sx={{
            color: "text.focus",
            marginRight: 2,
          }}
        >
          Gợi ý:
        </MDTypography>
        {["NB", "CHI PHÍ KHÁC"].map((item, index) => {
          return (
            <Chip
              key={index}
              label={item}
              onClick={() => setCompany(item)}
              sx={{ marginRight: 2, color: "text.focus", cursor: "pointer" }}
            />
          );
        })}
      </MDBox>
      <FormControlLabel
        sx={{
          ...inputStyle,
          marginLeft: 2,
        }}
        control={
          <Switch
            checked={isHighlight}
            value={isHighlight}
            // onChange={(e) => {
            //   debugger;
            //   setIsHighlight(!isHighlight);
            // }}
            onClick={(e) => {
              setIsHighlight(!isHighlight);
            }}
            inputProps={{ "aria-label": "secondary checkbox" }}
          />
        }
        label="Giao dịch nổi bật"
      />
      <Autocomplete
        disablePortal
        options={bankingStatusOptions}
        value={status}
        sx={{
          ...inputStyle,
          width: "100%",
        }}
        renderInput={(params) => <MDInput {...params} label="Trạng thái nhận" />}
        onChange={(e, newValue) => {
          setStatus(newValue);
        }}
      />
      <QuillInputField initialValue={content} onChange={(value) => setContent(value)} />
      {disableCreate && (
        <MDButton
          variant="contained"
          color="success"
          sx={{ position: "absolute", bottom: 10, right: 105 }}
          onClick={handleResetForm}
        >
          Xóa thông tin
        </MDButton>
      )}
      <MDButton
        type="submit"
        variant="contained"
        color={sidenavColor}
        sx={{ position: "absolute", bottom: 10, right: 15 }}
        disabled={isLoading || disableCreate}
      >
        {!create ? "Cập Nhật" : "Tạo"}
      </MDButton>
    </MDBox>
  );
}

export default UpdateForm;
